@font-face {
  font-family: "Roboto";
  src: url("../bundle-data/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../bundle-data/fonts/RobotoMono-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("../bundle-data/fonts/Radomir Tinkov - Gilroy-SemiBold.otf")
    format("opentype");
  font-weight: 600;
}

body {
  font-family: "Gilroy", "Arial", sans-serif;
}
